import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import LocalizationService from '@/services/localization-service'
import CommonTableActions from '@/constants/common-table-actions'

const NoticesTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/notice-items`,
  rowsPath: 'items',
  rowClickable: true,
  attachPageId: false,
  columns: [
    {
      label: 'notices.notice-text',
      field: 'locales',
      formatFn: (locales: any) => LocalizationService.getTranslate(locales, 'text'),
    },
    {
      label: 'notices.show-until',
      field: 'valid_until',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
    ...CommonTableActions,
  ],
  filters: [
  ],
}

export default NoticesTableConfig
