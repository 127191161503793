<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('notices.notice-items')"
      :config="config"
      @delete="handleDelete"
      @edit="handleEdit"
    >
      <template #buttons>
        <b-button
          size="md"
          variant="dark"
          class="mx-md-1"
          @click="handleEdit"
        >
          {{ $t('notices.add-item') }}
        </b-button>
      </template>
    </ok-table>
    <notice-item-modal
      v-model="showModal"
      :item="selectedItem"
      @updated="refreshTable"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import NoticesAPIs from '@/api/store/notices'
import NoticeItemModal from '@/views/user/notices/components/NoticeItemModal'
import NoticesTableConfig from './config/notices-table-config'

export default {
  components: {
    NoticeItemModal,
    OkTable,
    BButton,
  },
  data() {
    return {
      config: NoticesTableConfig,
      selectedItem: {},
      showModal: false,
    }
  },
  methods: {
    refreshTable() {
      this.selectedItem = null
      this.$refs.datatable.loadItems()
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => NoticesAPIs.delete(data.row.id)
          .then(res => {
            this.$notifySuccess(res.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
    handleEdit(data) {
      this.showModal = true
      this.selectedItem = data?.row ?? null
    },
  },
}
</script>
