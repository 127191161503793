<template>
  <ok-modal
    :value="value"
    size="lg"
    dialog-class="tables-modal"
    :title="$t('forms.'+(isEdit?'edit':'add'))"
    @change="$emit('input',false)"
  >
    <validation-observer ref="form">
      <error-alert
        v-if="error"
        :error="error"
      />
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <ok-date-time-picker
            v-model="valid_until"
            is-required
            type="datetime"
            :label="$t('notices.show-until')"
            :min-date="minDate"
          />
        </b-col>
        <b-col md="6" />
        <b-col
          v-for="(locale,index) in locales"
          :key="'locale'+index"
          md="6"
        >
          <ok-text-input
            v-model="locale.text"
            rules="required"
            :label="`${$t('notices.notice-text')} (${$t(`locales.${locale.locale}`)})`"
            :validation-name="`${$t('notices.notice-text')} ${locale.locale}`"
            :hide-errors="false"
          />
        </b-col>
      </b-row>
    </validation-observer>
    <template #actions>
      <div class="d-flex justify-content-end align-items-center">
        <b-button
          variant="outline-dark"
          :disabled="loading"
          @click="submit"
        >
          <b-spinner
            v-if="loading"
            small
            class="ml-1"
          />
          {{ $t('forms.save') }}
        </b-button>
        <b-button
          variant="dark"
          class="ml-1"
          @click="$emit('input',false)"
        >
          {{ $t('forms.cancel') }}
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import {
  BButton, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import OkModal from '@/@bya3/components/modals/OkModal'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import { ValidationObserver } from 'vee-validate'
import NoticesAPIs from '@/api/store/notices'
import OkDateTimePicker from '@/@bya3/components/inputs/OkDateTimePicker'
import dayjs from 'dayjs'

export default {
  name: 'NoticeItemModal',
  components: {
    OkDateTimePicker,
    ErrorAlert,
    OkTextInput,
    ValidationObserver,
    OkModal,
    BButton,
    BRow,
    BCol,
    BSpinner,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      error: null,
      locales: [],
      loading: false,
      minDate: dayjs().add(1, 'h').format('YYYY-MM-DD HH:mm'),
      valid_until: null,

    }
  },
  computed: {
    isEdit() {
      return !!this.item?.id
    },
  },
  watch: {
    item() {
      this.error = null
      this.setForm()
    },
  },
  mounted() {
    this.setForm()
  },
  methods: {
    setForm() {
      this.locales = []
      this.userLocales.forEach(loc => {
        const labels = this.item?.locales ?? []
        const index = labels.findIndex(l => l.locale === loc)
        if (index > -1) {
          this.locales.push(this.item.locales[index])
        } else {
          this.locales.push({ locale: loc, text: '' })
        }
      })
      this.valid_until = this.isEdit ? this.item.valid_until : this.minDate
    },
    async submit() {
      this.error = null
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.error = this.$tc('errors.field-invalid', 2)
        this.notifyError = this.$tc('errors.field-invalid', 2)
      } else {
        this.loading = true
        const form = { locales: JSON.stringify(this.locales), valid_until: this.valid_until }
        if (this.isEdit) {
          form.id = this.item.id
        }
        await NoticesAPIs.process(form).then(res => {
          if (res.error === 0) {
            this.$notifySuccess(res.message)
            this.$emit('updated')
            this.$emit('input', false)
          }
        }).catch(e => { this.error = e })
          .finally(() => { this.loading = false })
      }
    },
  },
}
</script>
